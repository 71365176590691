html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.login-modal {
  position: fixed;
  right: 0%;
  top: 0;
  padding: .5rem 1rem;
  margin: .5rem 1rem;
  max-width: 45ch;
  color: #fff;
  border-radius: 3px;
}

.loader-conatiner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cccccc80;
  backdrop-filter: blur(2px)
}

.loader-conatiner>.custom-loader {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  border-left: .8em solid #ff8300;
  border-right: .8em solid #3D7CC9;
  animation: spin 1.2s infinite linear;
}

.mat-dialog-container{
  padding: 0 0px 22px 0px !important;
  border-radius: 22px 23px 23px 23px !important;
}
.cdk-overlay-pane{
  width: 50%;
}
.mat-select-panel-wrap {
  flex-basis: 32%!important;
}
@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
